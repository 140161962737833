import { Container, Image, Link, Box, Heading } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

function Prize() {
  return (
    <Container>
      <br />
      <Heading>Prize</Heading>
      <br />
      <Box>
        The first player reaching level 5 will be able to claim a piece of fine
        NFT Art: <br />
        <br />
        <b>Ethereal State #772</b> by{" "}
        <Link
          style={{ textDecoration: "underline dotted" }}
          href="https://twitter.com/GenuineHumanArt"
          isExternal
        >
          Genuine Human Art
        </Link>
        <br />
        (collection{" "}
        <Link
          style={{ textDecoration: "underline dotted" }}
          href="https://opensea.io/collection/ethereal-states"
        >
          Ethereal States
        </Link>
        , 2022)
      </Box>
      <br />
      <Box>
        <Image src="https://lh3.googleusercontent.com/-2e-1fAGY41vUmWGnxx4XFm7AFSuNZ0ewhCdJco4otFy1YaUBVKMq1QuHLf2AxGbJrluLHpY-hPbJVs7lufMMu8Mlv1tiv3TK40OrOA=s0"></Image>
      </Box>
      <br />
      <s>
        The NFT has been bought by the creators of TicTacTie and transferred to
        the TicTacTie contract address. No one but the winner will be able to
        claim it.
      </s>
      <br />
      The prize has been won. While waiting for a new prize, you can still play
      and win on-chain generated <NavLink to="/ties">Ties</NavLink> of different
      rarities!
    </Container>
  );
}

export default Prize;
