const COUNTRIES = [
  "Germany",
  "Italy",
  "Japan",
  "Bulgaria",
  "Hungary",
  "Romania",
  "Slovakia",
  "Austria",
  "Ethiopia",
  "China",
  "Australia",
  "Brazil",
  "Canada",
  "New Zealand",
  "South Africa",
  "Russia",
  "United Kingdom",
  "United States",
  "Argentina",
  "Bolivia",
  "Chile",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Iraq",
  "Lebanon",
  "Liberia",
  "Mexico",
  "Mongolia",
  "Nicaragua",
  "Panama",
  "Paraguay",
  "Peru",
  "Saudi Arabia",
  "Turkey",
  "Uruguay",
  "Venezuela",
  "Algeria",
  "Albania",
  "Belgium",
  "Burma",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Greece",
  "Iceland",
  "India",
  "Iran",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Morocco",
  "The Netherlands",
  "New Guinea",
  "Norway",
  "Philippines",
  "Poland",
  "Singapore",
  "Syria",
  "Thailand",
  "Tunisia",
];

const FLAGS = [
  "🇩🇪",
  "🇮🇹",
  "🇯🇵",
  "🇧🇬",
  "🇭🇺",
  "🇷🇴",
  "🇸🇰",
  "🇦🇹",
  "🇪🇹",
  "🇨🇳",
  "🇦🇺",
  "🇧🇷",
  "🇨🇦",
  "🇳🇿",
  "🇿🇦",
  "🇷🇺",
  "🇬🇧",
  "🇺🇸",
  "🇦🇷",
  "🇧🇴",
  "🇨🇱",
  "🇨🇴",
  "🇨🇷",
  "🇨🇺",
  "🇩🇴",
  "🇪🇨",
  "🇪🇬",
  "🇸🇻",
  "🇬🇹",
  "🇭🇹",
  "🇭🇳",
  "🇮🇶",
  "🇱🇧",
  "🇱🇷",
  "🇲🇽",
  "🇲🇳",
  "🇳🇮",
  "🇵🇦",
  "🇵🇾",
  "🇵🇪",
  "🇸🇦",
  "🇹🇷",
  "🇺🇾",
  "🇻🇪",
  "🇩🇿",
  "🇦🇱",
  "🇧🇪",
  "🇲🇲",
  "🇨🇿",
  "🇩🇰",
  "🇪🇪",
  "🇫🇮",
  "🇫🇷",
  "🇬🇷",
  "🇮🇸",
  "🇮🇳",
  "🇮🇷",
  "🇱🇻",
  "🇱🇹",
  "🇱🇺",
  "🇲🇦",
  "🇳🇱",
  "🇵🇬",
  "🇳🇴",
  "🇵🇭",
  "🇵🇱",
  "🇸🇬",
  "🇸🇾",
  "🇹🇭",
  "🇹🇳",
];

export function countryToId(country: string) {
  var tokenId = FLAGS.indexOf(country);
  if (tokenId === -1) {
    tokenId = COUNTRIES.indexOf(country);
  }
  return tokenId + 1;
}

export function tokenIdToFlag(tokenId: number) {
  return FLAGS[tokenId - 1];
}

export function tokenIdToCountry(tokenId: number) {
  return COUNTRIES[tokenId - 1];
}
